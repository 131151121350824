import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Radio } from '..'
import { LoadingArea } from '../../@types/LoadingArea'

type Props = {
	loadingAreaId?: string
	loadingAreas: LoadingArea[]
	loadingStationId?: string
}

export function StationSelector({
	loadingAreaId,
	loadingStationId,
	loadingAreas
}: Props) {
	const navigate = useNavigate()
	const handleChange = useCallback(
		(loadingAreaId: string, stationId: string) => {
			const urlParams = new URLSearchParams(window.location.search)
			const deviceExternalToken =
				urlParams.get('device_external_token') || ''
			navigate(
				`/${loadingAreaId}/stations/${stationId}?device_external_token=${deviceExternalToken}`
			)
		},
		[navigate]
	)

	const valueGroups = useMemo(() => {
		return loadingAreas.map((area) => ({
			id: area._id,
			title: area.name,
			values: area.stations.map((station) => ({
				id: station._id,
				title: station.name
			}))
		}))
	}, [loadingAreas])

	return (
		<Radio
			title="Work Station"
			subtitle="Select the kitchen you're working for"
			selectedGroup={loadingAreaId}
			selectedValue={loadingStationId}
			valueGroups={valueGroups}
			onChange={handleChange}
		/>
	)
}
