export enum PreflightCheckSettingFieldType {
	BOOLEAN = 0,
	STRING = 1,
	NUMBER = 2,
	RATING = 3
}

export type PreflightCheckSettingsFieldAttributes = {
	min_limit?: number
}

export type PreflightCheckSettingsField = {
	type: PreflightCheckSettingFieldType
	field: string
	name: string
	is_required: boolean
	value: string | number | boolean | undefined
	attributes?: PreflightCheckSettingsFieldAttributes
}

export type PreflightCheckSettings = {
	completed?: boolean
	fields: PreflightCheckSettingsField[]
}

export type PreflightCheckValue = {
	value: string | number | boolean | undefined
	field: string
}

export type PreflightCheck = {
	values: PreflightCheckValue[]
}
